import React, { useState } from 'react';

/** 
@desc manages (read & update) given key in storage and sets default on first load or if the key is not 
already present in storage
@param {string} keyName - actual field to look for in storage (e.g language).
@param {string} defaultValue - default value to set in local storage in case the key is not yet stored in 
storage (e.g useLocalStorage('language', 'en'))
*/
const useLocalStorage = (keyName, defaultValue) => {
    const [storedLanguage, setStoredLanguage] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });
    const setLanguageInStorage = (newValue) => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) { }
        setStoredLanguage(newValue);
    };
    return [storedLanguage, setLanguageInStorage];
};

export default useLocalStorage;