import React, { useEffect } from "react";
import { createContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { LANGUAGE_ARABIC, LANGUAGE_ENGLISH } from "./languages";
import translationsEn from "../../locales/en/translations.json";
import translationsAr from "../../locales/ar/translations.json";
import { useI18next } from "gatsby-plugin-react-i18next";

const initialState = {
  language: LANGUAGE_ARABIC,
  updateLanguage: () => {},
  translations: translationsAr,
};

const StateContext = createContext(initialState);

export function StateProvider({ children }) {
  const { i18n } = useI18next();
  const [language, setLanguage] = useState(LANGUAGE_ARABIC);
  const [translations, setTranslations] = useState(translationsAr);
  const [storedLanguage, setLanguageInStorage] = useLocalStorage(
    "language",
    i18n.language === LANGUAGE_ENGLISH &&
      window.location.pathname.startsWith("/en")
      ? LANGUAGE_ENGLISH
      : window.location.pathname.startsWith("/en")
      ? LANGUAGE_ENGLISH
      : LANGUAGE_ARABIC
  );

  // pick language from storage on first load and set it in app state.
  // If language is not already in storage the useLocalStorage hook will set to English i.e 'en'
  // read more about it in useLocalStorage hook
  useEffect(() => {
    updateTranslations(storedLanguage);
    setLanguage(storedLanguage);
  }, [storedLanguage]);

  useEffect(() => {
    // setLanguageInStorage(
    //   i18n.language === LANGUAGE_ENGLISH &&
    //     window.location.pathname.startsWith("/en")
    //     ? LANGUAGE_ENGLISH
    //     : window.location.pathname.startsWith("/ar")
    //     ? LANGUAGE_ARABIC
    //     : LANGUAGE_ENGLISH
    // );
  }, [window.location.pathname]);

  /** 
    @desc updates the language in app state and in local storage
    @param {string} lang - new language value for updating (e.g updateLanguage('en') or updateLanguage('ar'))
  */
  const updateLanguage = (lang) => {
    setLanguageInStorage(lang);
    setLanguage(lang);
    updateTranslations(lang);
  };

  /** 
  @desc updates the language translations object in app state.
  @param {string} lang - The name of the language to load translations for
  */
  const updateTranslations = (lang) => {
    if (lang === LANGUAGE_ARABIC) {
      setTranslations(translationsAr);
    } else {
      setTranslations(translationsEn);
    }
  };

  return (
    <StateContext.Provider value={{ language, updateLanguage, translations }}>
      {children}
    </StateContext.Provider>
  );
}

export default StateContext;
