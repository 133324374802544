exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-brochures-jsx": () => import("./../../../src/pages/brochures.jsx" /* webpackChunkName: "component---src-pages-brochures-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-craftsmanship-jsx": () => import("./../../../src/pages/craftsmanship.jsx" /* webpackChunkName: "component---src-pages-craftsmanship-jsx" */),
  "component---src-pages-design-jsx": () => import("./../../../src/pages/design.jsx" /* webpackChunkName: "component---src-pages-design-jsx" */),
  "component---src-pages-electric-jsx": () => import("./../../../src/pages/electric.jsx" /* webpackChunkName: "component---src-pages-electric-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-innovation-jsx": () => import("./../../../src/pages/innovation.jsx" /* webpackChunkName: "component---src-pages-innovation-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-offers-jsx": () => import("./../../../src/pages/offers.jsx" /* webpackChunkName: "component---src-pages-offers-jsx" */),
  "component---src-pages-owner-jsx": () => import("./../../../src/pages/owner.jsx" /* webpackChunkName: "component---src-pages-owner-jsx" */),
  "component---src-pages-performance-jsx": () => import("./../../../src/pages/performance.jsx" /* webpackChunkName: "component---src-pages-performance-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-trade-in-jsx": () => import("./../../../src/pages/trade-in.jsx" /* webpackChunkName: "component---src-pages-trade-in-jsx" */),
  "component---src-pages-warranty-jsx": () => import("./../../../src/pages/warranty.jsx" /* webpackChunkName: "component---src-pages-warranty-jsx" */),
  "component---src-template-model-jsx": () => import("./../../../src/template/model.jsx" /* webpackChunkName: "component---src-template-model-jsx" */),
  "component---src-template-offer-jsx": () => import("./../../../src/template/offer.jsx" /* webpackChunkName: "component---src-template-offer-jsx" */)
}

